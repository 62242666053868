if ("requestIdleCallback" in window) {
    requestIdleCallback(initApp)
} else {
    setTimeout(initApp, 300)
}

async function initApp() {
    setTimeout(async () => {
        const [{ createApp, nextTick }, { createPinia }, axios, LanguageSwitch] = await Promise.all([
            import("vue"),
            import("pinia"),
            import("@/app/axios.ts"),
            import("@/components/Frontend/LanguageSwitch.vue"),
        ])

        const pinia = createPinia()

        const elements = {
            languageSwitch: document.querySelector(".language-switch__text"),
        }

        const languageSwitch = document.querySelector(".language-switch")
        const shortLinkNotify = document.getElementById("copy-link-notify")
        const adminBtnCopyLink = document.querySelector("#copyPageUrl")
        const adminBtnCleanCache = document.querySelector("#cleanCache")
        const checkUser = document.body.classList.contains("logged-in")

        // Отложенная инициализация ThemeSwitcher
        if (languageSwitch) {
            if ("requestIdleCallback" in window) {
                requestIdleCallback(() => initThemeSwitcher())
            } else {
                setTimeout(() => initThemeSwitcher(), 1000)
            }
        } else {
            initThemeSwitcher()
        }

        bindSwitcherBtn()

        function bindSwitcherBtn() {
            const switchIcon = elements.languageSwitch
            let footerAppLoaded = false

            if (switchIcon) {
                switchIcon.addEventListener(
                    "click",
                    (e) => {
                        if (!footerAppLoaded) {
                            initFooterApp(pinia, true).then(() => {
                                footerAppLoaded = true
                                setTimeout(() => {
                                    document.querySelector(".language-switch__text").click()
                                    rebindEventListeners()
                                }, 600)
                            })
                        } else {
                            switchIcon.click()
                        }
                    },
                    { passive: false },
                )
            }
        }

        async function copyShortLink() {
            try {
                const input = document.createElement("input")
                const shortLink = await getShortLink()
                input.value = shortLink
                document.body.appendChild(input)
                input.select()
                document.execCommand("copy")
                document.body.removeChild(input)
                showNotification("Short link copied to clipboard!", "success")
            } catch (err) {
                showNotification("Failed to copy short link", "error")
                console.error("Error copying short link:", err)
            }
        }
        async function cleanCacheLink() {
            try {
                const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content")
                const requestedUrl = window.location.href // Get the current page URL
                const response = await fetch("/backend/clean-cache", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRF-TOKEN": csrfToken,
                    },
                    body: JSON.stringify({ requestedUrl }), // Send the requested URL
                })

                const result = await response.json()
                if (response.ok) {
                    showNotification(result, "success", 120)
                } else {
                    showNotification(result, "error", 120)
                }
            } catch (err) {
                showNotification("Failed to clean cache", "error", 120)
                console.error("Error during the clean cache:", err)
            }
        }

        async function getShortLink() {
            const data = { url: window.location.href }
            const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content")
            try {
                const response = await fetch("/backend/get-short-link", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRF-TOKEN": csrfToken,
                    },
                    body: JSON.stringify(data),
                })

                if (!response.ok) {
                    throw new Error("Network response was not ok")
                }

                let shortLink = await response.text()
                shortLink = JSON.parse(shortLink)

                if (navigator.clipboard && shortLink) {
                    await navigator.clipboard.writeText(shortLink)
                }
                return shortLink
            } catch (error) {
                showNotification("Error fetching short link!", "error")
                console.error("Error fetching short link:", error)
                throw error
            }
        }

        function showNotification(message, type, left) {
            shortLinkNotify.innerText = message
            shortLinkNotify.classList.add(type)
            shortLinkNotify.style.display = "block"
            if (left) {
                shortLinkNotify.style.left = `${left}px`
            }
            setTimeout(() => {
                shortLinkNotify.classList.remove(type)
                shortLinkNotify.style.display = "none"
                shortLinkNotify.innerText = ""
            }, 2000)
        }

        function rebindEventListeners() {
            const themeModeElement = document.getElementById("theme-mode")
            if (themeModeElement) {
                themeModeElement.addEventListener("click", (e) => {
                    e.preventDefault()
                    const newTheme = localStorage.getItem("theme") === "dark" ? "light" : "dark"
                    const htmlElement = document.querySelector("html")
                    htmlElement.classList.toggle("light-mode", newTheme === "light")
                    htmlElement.classList.toggle("dark-mode", newTheme === "dark")
                    localStorage.setItem("theme", newTheme)
                })
            }

            if (adminBtnCopyLink) {
                adminBtnCopyLink.addEventListener("click", copyShortLink, { passive: true })
            }

            if (adminBtnCleanCache) {
                adminBtnCleanCache.addEventListener("click", cleanCacheLink, { passive: true })
            }

            if (!checkUser) {
                const toLogin = document.querySelector("#toLogin")
                if (toLogin) {
                    toLogin.addEventListener("click", (event) => {
                        event.preventDefault()
                        localStorage.setItem("to_licence", "1")
                        document.querySelector("#btn-login").click()
                    })
                }
            }

            document.querySelectorAll(".site-footer .footer-main-linkbox").forEach((linkBox) => {
                linkBox.addEventListener(
                    "click",
                    () => {
                        if (window.matchMedia("(max-width: 767px)").matches) {
                            linkBox.classList.toggle("expanded")
                        }
                    },
                    { passive: true },
                )
            })
        }

        function initThemeSwitcher() {
            const htmlElement = document.querySelector("html")
            const themeModeElement = document.getElementById("theme-mode")
            const themeIconSun = themeModeElement?.querySelector(".sprite-icon-sun")
            const themeIconMoon = themeModeElement?.querySelector(".sprite-icon-moon")

            const setTheme = (theme) => {
                localStorage.setItem("theme", theme)
                htmlElement.classList.toggle("light-mode", theme === "light")
                htmlElement.classList.toggle("dark-mode", theme === "dark")
                if (themeIconSun) {
                    themeIconSun.classList.toggle("d-none", theme === "light")
                    themeIconMoon.classList.toggle("d-none", theme === "dark")
                }
            }

            const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches
            const isLightMode = window.matchMedia("(prefers-color-scheme: light)").matches

            if (!localStorage.getItem("theme")) {
                if (isDarkMode) {
                    setTheme("dark")
                } else if (isLightMode) {
                    setTheme("light")
                } else {
                    console.warn("The theme is not specified")
                }
            } else {
                setTheme(localStorage.getItem("theme"))
            }

            const bindThemeMode = () => {
                if (themeModeElement) {
                    themeModeElement.addEventListener("click", (e) => {
                        e.preventDefault()
                        const newTheme = localStorage.getItem("theme") === "dark" ? "light" : "dark"
                        setTheme(newTheme)
                    })
                }
            }

            setTimeout(bindThemeMode, 500)
        }

        async function initFooterApp(pinia, open = true) {
            return new Promise((resolve) => {
                const FooterApp = createApp({})
                FooterApp.config.compilerOptions.whitespace = "preserve"
                FooterApp.provide("open", open)
                FooterApp.component("language-switch", LanguageSwitch.default)
                FooterApp.use(pinia)
                FooterApp.mount(".site-footer")
                nextTick(() => {
                    resolve()
                })
            })
        }

        setTimeout(() => {
            rebindEventListeners()
            initThemeSwitcher()
        }, 2000)
    }, 600)
}
